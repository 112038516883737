<template>
  <div>
    <titleNav title="社保小助手" :left-arrow="true" left-text="" />
    <div class="text" style="font-size:16px;padding: 10px;">
      一、乙方委托甲方代理其以下社会保险的申报以及相关业务办理：
      <div>1、灵活就业养老保险 </div>
      2、灵活就业医疗保险 
      <div>社会保险的缴费数额参照石家庄市的标准执行。</div>
      二、收费标准及缴费时间 
      <div>
        1、甲方在为乙方提供灵活就业人员社会保险服务期间，乙方应一次性支付甲方服务费 240 元/年。
      </div>
      <div>
        2、服务费按年收取，从开户申请日期起，预交一年，中途中断概不退款，服务费到期后预收取下年度服务费。乙方应于到期前缴纳下年度服务费。
      </div>
      <div>
        3、乙方未办理相关中断手续的，协议条款继续执行，转出时乙方在线申请中断或联系客服，甲方将于20个工作日内为其办理完毕。
      </div>
      <div>
        4、因乙方原因未及时办理中断手续的，由此产生的服务费用，全权由乙方负责。
      </div>
      <div>
        5、退休前3个月提前联系办理退休事宜，逾期造成的后果本人自行承担一切责任。 
      </div>
      三、甲方的责任和权利： 
      <div>
        1、受乙方委托管理其人事档案关系（乙方自愿选择是否在我处存放档案）； 
      </div>
      <div>
        2、甲方可为乙方免费办理社会保险相关手续（包含医保待遇报销、生育报销，参保人员去世后的死亡抚恤金、丧葬费的申报工作等）；
      </div>
      <div>
        3、乙方需要甲方其它服务，如有另行收费，具体收费项目及标准以我公司收费标准执行（如保险转移手续等）。 
      </div>
      四、乙方的责任和权利： 
      <div>
        1、 乙方须按照省市规定及时且足额缴纳各项保险费用，若因缴费不及时造成的一切后果，由乙方承担。注：现阶段可从河北税务APP或者到指定银行进行缴纳。
      </div>
      <div>
        2、乙方委托甲方提供服务期间个人情况有所变化（姓名、学历、联系方式等）时，应及时通知甲方，并将有关材料以书面形式送至甲方，因乙方提供上述资料不准确、不及时等原因造成的后果由乙方负责；
      </div>
      <div>
        3、乙方需将人事关系由甲方转出或中断保险关系时，应一次性结算清相关费用。费用未结清，甲方有权不予办理相关业务；
      </div>
      <div>
        4、乙方办理开户、中断和档案调转业务时，需本人亲自办理。特殊情况需找人代办，委托人需提供乙方和委托人的身份证复印件及乙方写的委托证明书，否则不予办理。 
      </div>
      五、免责条款 因下列原因导致灵活就业保险无法正常办理手续，本公司免于承担相应责任： 
      <div>
        1、因自然灾害等不可抗力原因；
      </div>
      <div>
        2、因国家相关政策或河北省社保、医保系统故障导致未为参保人员办理的相关业务。
      </div>
      六、本人已阅读并确认协议所有条款，并保证上述身份信息和社保信息准确有效，如因个人原因报错或不及时告知变更后的信息，使灵活就业保险无法正常增加或中断，退休手续等相关业务无法正常办理，乙方自行承担一切责任。
      
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
export default {
  components: {
    titleNav,
  }
}
</script>